.Me {
  padding-bottom: 100px;
  .container {
    text-align: left;
    padding: 10px;
    max-width: 600px;
    margin: 0 auto;
    color: $white;
    transition: 200ms;
    transition-delay: 1ms;

    p {
      margin-bottom: 20px;
    }
    .bigtext {
      font-size: 1.2rem;
      font-weight: normal;
    }

    .exp-container {
      display: flex;
      justify-content: space-between;
      height: 30px;
      align-items: center;
      text-transform: uppercase;
      border-bottom: 2px solid $primary1;
      margin-bottom: 10px;

      .title {
        font-size: 1.5rem;
      }
      svg {
        width: 100px;
        height: 100%;
        filter: drop-shadow($shadow1);
        fill: $white;
      }
    }

    h2.secondary {
      color: $white;
    }

    .bonus-container {
      margin-top: 40px;
      align-items: center;
      transition: 200ms;
      transition-delay: 1ms;

      &:hover {
        border-left: solid 2px $sec1;
        box-shadow: $glow1;
      }
      h2 {
        margin-top: 10px;
      }

      svg {
        fill: $white;
        animation-duration: 1s;
        animation-name: glow;
        animation-iteration-count: infinite;
        animation-direction: alternate;
      }
    }
  }
}

@keyframes glow {
  from {
    opacity: 0.9;
    filter: drop-shadow($shadow1);
  }
  to {
    opacity: 1;
    filter: drop-shadow($glow1);
    transform: scale(1.02);
  }
}
