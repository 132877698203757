.RealCard {
  background: $grad3;
  margin: 20px auto;
  padding: 10px;
  border-left: solid 2px $primary1;
  box-shadow: $shadow1;
  overflow: hidden;
  transition: 400ms;
  transition-delay: 1ms;

  color: $white;
  &:hover {
    border-left: solid 2px $sec1;
    box-shadow: $glow1;
  }
  .top-container {
    display: flex;
    margin-bottom: 20px;
    .main {
      width: 50%;

      img {
        max-height: 50px;
        max-width: 90%;
      }
    }
    .tech {
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      flex-flow: row wrap;
      justify-content: flex-end;
      svg {
        width: 32px;
        height: 32px;
      }
      .Icon {
        width: 55px;
        height: 55px;
        .label {
          font-size: 0.6rem;
        }
      }
    }
  }
  .bottom-container {
    display: flex;
    justify-content: center;
  }
}
