.Icon {
  width: 96px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  transition: 400ms;
  transition-delay: 1ms;
  svg {
    width: 48px;
    height: 48px;
    fill: $white !important;
    filter: drop-shadow($shadow1);
  }

  .label {
    font-size: 1rem;
    color: $white;
    padding-top: 5px;
    text-transform: uppercase;
    font-weight: normal;
  }
}
