.Home {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 400px;
  height: 100vh;
  // text-shadow: $shadow1;
  color: $white;
  font-size: 3rem;
  font-weight: bold;

  .ta-left {
    text-align: left;
  }

  .ta-right {
    text-align: right;
  }

  .welcome {
    &__container {
      padding: 1rem;
      width: 100%;
      max-width: 400px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &__text {
      color: $primary1;
    }

    &__emphasis {
      z-index: 999;

      font-family: $font2;
      text-transform: uppercase;
      display: inline-block;
      color: $white;
      margin: 2rem auto;
      font-size: 4rem;
      line-height: 3.2rem;
    }
  }
}
