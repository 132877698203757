.Header {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  height: 150px;
  position: relative;
  background: $primary1;

  //   overflow: hidden;
  &__overlay {
    position: absolute;

    left: 120px;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    perspective: 200px;

    &--content {
      position: absolute;
      top: -500%;
      left: -50%;
      right: -45%;
      bottom: -350%;

      opacity: 0.06;
      color: white;
      font-size: 4rem;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 3rem;
      overflow-wrap: break-word;
      animation: spin 100s linear infinite;
      transform-origin: center;
    }
  }
  @keyframes spin {
    0% {
      transform: rotateZ(0deg) rotateX(-10deg);
    }
    50% {
      transform: rotateZ(180deg) rotateX(10deg);
    }
    100% {
      transform: rotateZ(360deg) rotateX(-10deg);
    }
  }
  &__background {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    object-fit: cover;
    height: 100%;
    width: 200px;
    overflow: hidden;

    &--hoverCatcher {
      z-index: 200;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 30px;
      right: 80px;
    }

    img {
      position: absolute;
      z-index: 20;
      opacity: 0.2;
      left: -50px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      mix-blend-mode: darken;
      transition: 400ms;
    }
  }
  &__bubble {
    display: flex;
    opacity: 0;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    position: absolute;
    height: 80px;
    width: 100px;
    top: 15px;
    left: 100px;
    color: white;
    background: $dark1;
    clip-path: polygon(0 0, 100% 0, 100% 75%, 72% 80%, 0 99%, 32% 78%, 0% 75%);
    transition: 200ms;
    transform: rotate(-5deg);
  }

  .title {
    z-index: 20;
    letter-spacing: -2px;
    position: absolute;
    font-family: $font2;
    font-weight: bold;
    top: 0px;
    right: 1px;
    text-align: right;
    text-transform: uppercase;
    color: $white;
    font-size: 3rem;
    line-height: 2.2rem;
  }
}
