.Main {
  padding-bottom: 100px;
  .container {
    h3 {
      z-index: 20;
    }
    transition: 200ms;
    transition-delay: 1ms;

    text-align: left;
    padding: 10px;
    max-width: 600px;
    margin: 0 auto;
  }

  .icon-container {
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    perspective: 200px;
  }
}
