.Button {
  color: $white;
  // border-radius: 10px;
  transition: 200ms;
  text-shadow: $shadow1;
  text-decoration: underline;

  &:hover {
    border-color: $primary1;
    color: $primary1;
  }
}
