.NavItem {
  z-index: 100;
  display: flex;
  margin-bottom: -22px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .circle {
    width: 20px;
    height: 20px;
    background: white;
    transition: 300ms;
    clip-path: polygon(50% 50%, 0 0, 100% 0);
    transform: translateY(-6px);

    &.active {
      background: $primary1 !important;
      transform: translateY(20px) scale(1.5);
    }
  }

  .label {
    margin-top: 10px;
    margin-bottom: 5px;
    padding: 5px 0px;
    color: $white;
    font-size: 1.2rem;
    font-variant: small-caps;
    transition: 300ms;
    border-bottom: 2px solid white;

    font-weight: bold;

    &.active {
      color: $dark1 !important;
      transition: 600ms;
      transform: translateY(18px);
      border-bottom: 2px solid transparent;
    }
  }
}
