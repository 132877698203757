.Real {
  .container {
    text-align: left;
    padding: 10px;
    max-width: 600px;
    margin: 0 auto;
  }
  .info-container {
    margin: 40px auto;
    max-width: 200px;
    color: $white;
    font-size: 1.5rem;
    text-shadow: $shadow1;
  }
  .contact {
    display: flex;
    width: 200px;
    flex-direction: column;
    color: $white;
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
    text-shadow: $shadow1;
    margin: 0 auto;
    transition: 200ms;
    padding-bottom: 40px;

    &:hover {
      color: $primary1;
      svg {
        fill: $primary1;
      }
    }
    svg {
      width: 40px;
      height: 40px;
      fill: $white;
      transition: 200ms;
    }
  }
}
