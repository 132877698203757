@import url('https://fonts.googleapis.com/css2?family=Alata&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;700&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border: none;
  text-decoration: none;
  // cursor: none;
  &:focus {
    outline: none;
  }
}

$font1: 'Outfit', sans-serif;
$font2: 'Outfit', sans-serif;
$dark1: #0c0a13;
$dark2: #0c060e;
$primary1: #f4487b;
$primary2: #eb6488;
$sec1: #4eafd2;
$sec2: #268fff;
$white: #ffffff;

$grad1: linear-gradient(180deg, $dark1 0%, $dark2 100%);
$grad2: linear-gradient(90deg, $sec1 0%, $sec2 100%);
$grad3: linear-gradient(225deg, $dark1 0%, $dark2 100%);

$shadow1: 0px 2px 8px #00000099 outset, 0 0 0 transparent inset;
$glow1: 0px 2px 8px #00000099, 0px 0px 40px 5px #00bbff0f inset;
$shadow1-i: 0px -4px 30px #00000099;

body {
  font-family: $font1;
  background: $grad1;
  background-attachment: fixed;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $dark1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary1;
    border-radius: 20px;
  }
}

.cursor {
  z-index: 500;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: invert(0.8);

  transform: translate(-50%, -50%);

  pointer-events: none;
}
.cursor2 {
  z-index: 500;
  position: absolute;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 1000px 500px rgba(0, 0, 0, 0.3);
  mix-blend-mode: screen;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.cursor3 {
  z-index: 500;
  position: absolute;

  width: 1px;
  height: 1px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 100px 20px rgba(255, 255, 255, 0.85);
  mix-blend-mode: screen;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
@media (hover: none) {
  .cursor,
  .cursor2 {
    display: none;
  }
}

.main-container {
  // height: 100vh;
  max-width: 800px;
  margin: 0 auto;
  overflow: hidden;
}

.bonus-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem auto;
  padding: 10px;
  box-shadow: $shadow1;
  background: $grad3;
  border-radius: 0px;
  border-left: solid 2px $primary1;

  .content {
    color: $white;
    font-size: 1.5rem;
    text-transform: uppercase;
  }
}
h1,
h2,
h3 {
  font-weight: normal;
}

h2 {
  color: $white;
  font-family: $font2;
  font-size: 2.4rem;
  margin-bottom: 1rem;
  margin-top: 40px;
  font-variant: small-caps;
}

h3 {
  color: $primary1;
  font-family: $font2;

  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

em {
  text-shadow: $shadow1;
}
