.Footer {
  color: $white;
  background: $dark2;
  position: fixed;
  font-size: 0.6rem;
  font-family: $font2;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  text-align: center;
}
