.Nav {
  z-index: 100;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px 0px;
  .container {
    display: flex;
    max-width: 800px;
    margin: 0 auto;
    justify-content: space-around;
  }
}
